import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ProductsByPlatform from '../components/productsByPlatform'
import ProductsByCategory from '../components/productsByCategory'
import ProductsByAZ from '../components/productsByAZ'
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, } from 'mdbreact'
import get from 'lodash/get'

class TabsDefault extends React.Component {
  state = {
    activeItem: '1',
  }

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      })
    }
  }

  render() {
    const data = get(this.props, 'data')
    const az = get(this, 'props.data.az.edges')
    const platforms = get(this, 'props.data.platforms.edges')
    const categories = get(this, 'props.data.categories.edges')
    const heroImage = get(this, 'props.data.heroImage')
    return (
      <div id="sub-page">
        <Layout>
          <SEO
            title="Products by A-Z, Platform and Category"
            description="Our UNICOM Products listed alphabetically, by Platform or by Category"
            url="https://www.unicomsi.com/products/"
            image={"https://www.unicomsi.com" + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
          />

          <Hero
            class="intro-65"
            image={heroImage.childImageSharp.gatsbyImageData}
            foreimage={null}
            forealt=""
            title="Products"
            subtitle="Innovative software solutions for Enterprise IT."
            ctatext="Let's talk"
            ctaslug="/contact/"            
            type="root"
          />

          <section className="bg-white">
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <p className="font-w-400 text-xs-extra-large divlink text-opening-para">
                    UNICOM Systems has a broad range of solutions across many
                    platforms. Our award-winning software spans many different
                    operating systems and technologies including CICS automation,
                    system performance, application modernization and more.
                  </p>
                  <p className="text-medium mb-5 mt-3">                  
                    Designed to meet the demands of Fortune 500 and Global 2000
                    organizations, our customers rely upon UNICOM's innovative
                    technology and world-class support to power their business.
                  </p>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBNav className="nav-tabs mt-5 mb-0">
                    <MDBNavItem className="nav-item-border">
                      <a
                        className={ this.state.activeItem === '1' ? 'nav-link active text-xs-small text-medium-small' : 'nav-link text-xs-small text-medium-small' }
                        onClick={this.toggle('1')}
                        role="tab"
                      >
                        A-Z
                      </a>
                    </MDBNavItem>
                    <MDBNavItem className="nav-item-border">
                      <a
                        className={ this.state.activeItem === '2' ? 'nav-link active text-xs-small text-medium-small' : 'nav-link text-xs-small text-medium-small' }
                        onClick={this.toggle('2')}
                        role="tab"
                      >
                        Platform
                      </a>
                    </MDBNavItem>
                    <MDBNavItem className="nav-item-border">
                      <a
                        className={ this.state.activeItem === '3' ? 'nav-link active text-xs-small text-medium-small' : 'nav-link text-xs-small text-medium-small' }
                        onClick={this.toggle('3')}
                        role="tab"
                      >
                        Category
                      </a>
                    </MDBNavItem>
                  </MDBNav>
                  <MDBTabContent activeItem={this.state.activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                      <div className="intro-content">
                        <ProductsByAZ data={az} />
                      </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel">
                      <div className="intro-content">
                        <ProductsByPlatform data={platforms} />
                      </div>
                    </MDBTabPane>
                    <MDBTabPane tabId="3" role="tabpanel">
                      <div className="intro-content">
                        <ProductsByCategory data={categories} />
                      </div>
                    </MDBTabPane>
                  </MDBTabContent>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <p className="py-5">Can't find the product you're looking for?  Try checking our Group-wide <a href="https://www.unicomglobal.com/product-catalog/" className="effect" target="_blank">product catalog</a>.</p>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </section>
        </Layout>
      </div>
    )
  }
}
export default TabsDefault

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
    az: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: { frontmatter: { template: { eq: "products" }, hide: {ne: true} } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          fields {
            slug
          }
        }
      }
    }
    categories: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___categoryname }
      filter: { frontmatter: { categoryname: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            categoryname
            categorydescription
            related {
              link
              name
            }
          }
        }
      }
    }
    platforms: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___platformname }
      filter: { frontmatter: { platformname: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            platformname
            platformdescription
            related {
              link
              name
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
        }
      }
    }
`